import React, {
  useEffect,
  useState,
  useRef,
  createContext,
  useMemo,
  useContext,
} from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  Item1,
  Item2,
  Item3,
  Item4,
  Item5,
  Item6,
} from "../components/portfolioItems"
import Logo from "../components/logo"
import MonogramSVG from "../assets/monogram.svg"
import { gsap, CustomEase } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger.js"

import Header from "../components/header"
import UserContext from "../components/contextProvider"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

const scrollItems = [Item1, Item2, Item3, Item4, Item5, Item6]

export const MovingTitle = ({ children }) => {
  const ball = useRef()

  const context = useContext(UserContext)
  const { title, subtitle, visible } = context

  useEffect(() => {
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    const mouse = { x: pos.x, y: pos.y }
    const speed = 0.2

    const setupMouseHandler = e => {
      mouse.x = e.x
      mouse.y = e.y
    }

    if (window !== "undefined") {
      gsap.set(ball.current, { xPercent: -50, yPercent: -50 })

      const xSet = gsap.quickSetter(ball.current, "x", "px")
      const ySet = gsap.quickSetter(ball.current, "y", "px")

      window.addEventListener("mousemove", setupMouseHandler)

      gsap.ticker.add(() => {
        // adjust speed for higher refresh monitors
        const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio())
        pos.x += (mouse.x - pos.x) * dt
        pos.y += (mouse.y - pos.y) * dt
        xSet(pos.x)
        ySet(pos.y)
      })
    }

    return () => {
      if (window !== "undefined") {
        window.removeEventListener("mousemove", setupMouseHandler)
      }
    }
  }, [])

  return (
    <div
      id="cursor"
      ref={ball}
      className="h-[50px] w-[250px] fixed z-[99999] top-0 left-0 pointer-events-none text-center"
    >
      {visible && <p className="text-[28px] leading-none">{title}</p>}
      {visible && <p className="text-[14px] mt-[5px]">{subtitle}</p>}
    </div>
  )
}

const Container = ({ children }) => {
  return (
    <div className="h-[auto] pt-[110px] mobile:pt-[80px] tablet:pt-[90px] laptop:pt-[110px] large:pt-[120px] pb-[22px] px-[22px] tablet:pb-[30px] tablet:px-[30px] relative">
      <Header />
      {children}
    </div>
  )
}

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes
  const [items, setItems] = useState([0])

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    ScrollTrigger.update()

    ScrollTrigger.create({
      start: 0,
      end: "50%",
      onLeave: self => {
        setItems(current => [...current, 0])
      },
    })
  }, [items])

  return (
    <Layout isHomePage>
      <Seo title="All posts" />
      <Container className="container">
        {items.map(item => (
          <div className="clone mb-[144px]">
            {posts.map((post, index) => {
              const title = post?.title
              const category = post?.categories?.nodes[0]?.name
              const dotColor = post?.dotColour?.colour
              const PortfolioItem = scrollItems[index % 6]
              const postDetails = post?.postDetails

              return (
                <PortfolioItem
                  key={index}
                  title={title}
                  uri={post.uri}
                  category={category}
                  dotColor={dotColor}
                  postDetails={postDetails}
                  image={
                    post?.featuredImage?.node?.localFile?.childImageSharp?.fluid
                  }
                />
              )
            })}
          </div>
        ))}
        <BrowserView>
          <MovingTitle />
        </BrowserView>
      </Container>
      <Logo />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: menuOrder, order: ASC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            name
          }
        }
        dotColour {
          colour
        }
        postDetails {
          description
          descriptionTitle
          category
          agency
          scope {
            scopeLine
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
